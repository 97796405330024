import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
// import { TextField, Checkbox, FormControlLabel} from "@material-ui/core"
import axios from "axios"
import { toast } from "react-toastify";
import { API_ROOT } from "gatsby-env-variables"

class register extends React.Component {
  state = {
    name: "",
    position: "",
    company: "",
    email: "",
    telephone: "",
    address: "",
    newsletter: false,
    errors: {
      name: '',
      position: '',
      company: '',
      email: '',
      telephone: '',
      address: '',
    }
  }
  componentDidMount() {
    if(window.$crisp && window.$crisp !== undefined){
      window.$crisp.push(['do', 'chat:hide']);
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const name = target.name;
    let value = null;
    if(name === 'newsletter'){
      value = target.checked;
    } else {
      value = target.value;
    }
    this.setState({
      [name]: value
    })
  }
  validateForm = () => {
    let validate = false;
    let errors = this.state.errors;
    if(!this.state.name){
      errors.name = 'Required Filed';
      validate = false;
    } else {
      errors.name = '';
      validate = true;
    }
    if(!this.state.company){
      errors.company = 'Required Filed';
      validate = false;
    } else {
      errors.company = '';
      validate = true;
    }
    if(!this.state.email){
      errors.email = 'Required Filed';
      validate = false;
    } else {
      errors.email = '';
      validate = true;
    }
    this.setState({errors : errors})
    return validate
  }

  render() {
    return (
      <Layout pageInfo={{ pageName: "Sign up" }}>
        <SEO title="Request" />
        <Container fluid className={'inline-pages'}>
          <Row className={'text-center'}>
            <Col className={'inline-pages-title'} md={{span:10, offset:1}}>
              <p>Request</p>
            </Col>
            <Col className={'inline-pages-border'} md={{span:10, offset:1}}></Col>
            <Col md={{span:10, offset:1}} className={'top-z-index'}>
              <p className={'bread-crumb'}>
                home  /  Request
              </p>
            </Col>
          </Row>
          <Row>
            <Col className={'inline-pages-content-box'} md={{span:10, offset:1}}>
              {/*<p className={'request-title'}>Request login to Risklick platform</p>*/}
              <form
                method="POST"
                action="https://docs.google.com/forms/d/1cNkLZhL6RfVbSPDYCFJknuq_6BJn8tIj9c5lrhYSx7I/formResponse"
              >
                <Row>
                  <Col className={'mb20'} md={{span:5, offset:1}}>
                    {/* <TextField
                      label="Fullname"
                      className={'col-md-12'}
                      name="entry.682731495"
                      // value={this.state.name}
                      // onChange={this.handleInputChange}
                      // error={this.state.errors.name !== ''}
                      // helperText={this.state.errors.name}
                      required
                    /> */}
                  </Col>
                  <Col className={'mb20'} md={{span:5}}>
                    {/* <TextField
                      label="Position"
                      className={'col-md-12'}
                      name="entry.861258847"
                      // value={this.state.position}
                      // onChange={this.handleInputChange}
                      // error={this.state.errors.position !== ''}
                      // helperText={this.state.errors.position}
                    /> */}
                  </Col>
                  <Col className={'mb20'} md={{span:5, offset:1}}>
                    {/* <TextField
                      label="Company"
                      className={'col-md-12'}
                      name="entry.1846828849"
                      // value={this.state.company}
                      // onChange={this.handleInputChange}
                      // error={this.state.errors.company !== ''}
                      // helperText={this.state.errors.company}
                      required
                    /> */}
                  </Col>
                  <Col className={'mb20'} md={{span:5, offset:1}}>
                    {/* <TextField
                      label="Telephone"
                      className={'col-md-12'}
                      name="entry.1343915777"
                      // value={this.state.telephone}
                      // onChange={this.handleInputChange}
                      // error={this.state.errors.telephone !== ''}
                      // helperText={this.state.errors.telephone}
                    /> */}
                  </Col>
                  <Col className={'mb20'} md={{span:10, offset:1}}>
                    {/* <TextField
                      label="Address"
                      className={'col-md-12'}
                      name="entry.1424061"
                      // value={this.state.address}
                      // onChange={this.handleInputChange}
                      // error={this.state.errors.address !== ''}
                      // helperText={this.state.errors.address}
                    /> */}
                  </Col>
                  <Col className={'mb20 text-center'} md={{span:12}}>
                    <button type='submit' className={'btn submit-btn'}>
                      Submit request
                    </button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default register
